import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import Divider from '../../components/ui/Divider/Divider';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import SVGIcons from '../../components/utils/SVGIcons';
import { Direction } from '../../globals';
import '../sobre/about.scss';

const AboutWeb3: React.FC = () => {

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="O que é Web3?"
                url="sobre-web3/"
                description="O que é Web3?"
            />
            <PageBanner className="page-banner-single-item">
                <Fragment>
                    <h1>
                        O que é Web3?
                    </h1>
                </Fragment>
                <Fragment>
                </Fragment>
            </PageBanner>

            <PageSection>
                <Container>
                    <Group size={1}>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            Distópico, o atual cenário resultou na <b>idealização da Web3</b>. 
                            O termo foi cunhado ainda em 2014 por Gavin Wood, um dos cofundadores da famosa criptomoeda Ethereum, se referindo a um ambiente digital inteiramente <b>descentralizado, transparente e seguro</b> — promessas garantidas pelo uso da tecnologia de blockchain e de inteligência artificial.
                            </p>
                            <p>
                            Assim, os usuários poderiam trocar informações inteiramente verificáveis num livro de registro público da rede, conhecido como ledger, de maneira similar ao encontrado nas transações da criptomoeda original, o Bitcoin. Como cada mudança navegável seria registrada na tecnologia, os dados compartilhados não poderiam ser manipulados ou controlados por um agente mal-intencionado.
                            </p>
                            <p>
                            Além disso, o acesso à nova internet seria <b>irrestrito e não exigiria credenciais</b>, ou seja, dispensando um provedor ou serviço similar. 
                            Nesse contexto, os "sites" da nova tecnologia também dispensariam a criação de contas e identificação pessoal, possibilitando que os usuários se conectem anonimamente, sem intermediários.
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            <h2>Web3: a Internet "Leitura, Escrita e Posse"</h2>
                            Abandonando a dinâmica "tradicional", a proposta afirma que a Web3 deve ser: descentralizada, livre de permissões, equipada com sistemas nativos de pagamento e "livre de confiança". 
                            A descrição da tecnologia no blog do Ethereum explica que os princípios serão alcançados com o <b>incentivo financeiro aos usuários</b> e outros mecanismos econômicos.
                            </p>
                            <p>
                            Por exemplo, as propriedades e sites acessáveis seriam financiados pelos próprios internautas, que poderiam adquirir "parte da posse" em forma de tokens, garantindo influência na direção dos projetos e evitando sua centralização. 
                            Essas operações seriam realizadas nativamente, dispensando o uso de bancos e outras entidades financeiras, tornando-se "livres de confiança" em uma "terceira parte", através do uso da inteligência artificial.
                            </p>
                            <p>
                            O formato proposto, sumarizando, tornaria o consumidor em "dono" de parte da internet, contribuindo diretamente com sua construção e seu funcionamento. No entanto, o projeto ainda é uma empreitada distante, que deve enfrentar uma complicada série de obstáculos.
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            <h2>Problemas de "atualização" para a Web3</h2>
                            Apesar de interessante, cada ponto forte nos princípios da Web3 traz seu próprio problema. Possivelmente, o maior deles é a <b>curva de aprendizado</b> exigida para a maioria dos usuários, dificultando o acesso à tecnologia. 
                            Logo depois, é possível debater sobre questões ligadas à censura e anonimidade.
                            </p>
                            <p>
                            Enquanto o ambiente digital proposto seria livre de qualquer censura, ele também poderia se tornar fértil para outros problemas como a <b>desinformação e conteúdos maliciosos</b>, tópicos que já se mostraram um grande problema ainda em 2020, durante a pandemia da covid-19. Por outro lado, considerando o livro de registros públicos da rede, é possível afirmar que nenhum movimento é realmente privado e, assim, usuários que dependem da anonimidade por questões de segurança seriam indevidamente expostos.
                            </p>
                            <p>
                            Além disso, ainda há outro (grande) empecilho: as grandes empresas da tecnologia, que seriam diretamente prejudicadas pela transição e, certamente, buscam evitá-la como possível. 
                            Ironicamente, a proposta da Web3 atual dificilmente se consolidaria sem a regulamentação de entidades contra as Big Techs no mercado, conforme sugere a PCMag.
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <p>
                            <h2>Web3, na prática</h2>
                            Ainda sem implementação prática, a Web3 não passa de uma proposta. Desconsiderando os problemas teóricos do tópico anterior, a tecnologia atual também apresenta dificuldades próprias equiparáveis aos encontrados em outros projetos que utilizam soluções na blockchain. 
                            Entre eles, conforme detalhado no blog do Ethereum, estão:
                            <br/><b>- Escalabilidade:</b> as transações de dados são lentas, já que devem ser verificadas por todos os participantes na rede.
                            <br/><b>- Interface de usuário:</b> análogo à curva de aprendizado, o problema se refere aos passos que a tecnologia exige para ser utilizada, podendo soar complicada para os mais leigos.
                            <br/><b>- Acessibilidade de software:</b> inacessível pela maioria dos navegadores atuais, a Web3 ainda está distante para a maioria dos usuários.
                            <br/><b>- Custo:</b> mesmo já sendo possível, a implementação de códigos na blockchain ainda é um processo caro e pouco atrativo.
                            </p>
                        </Block>
                        <center><p>Conteúdo extraído do site <a href='https://www.tecmundo.com.br/mercado/238590-web3-entenda-tudo-voce-precisa-saber-o-conceito.htm' target='_blank'>TecMundo</a>.</p></center>
                    </Group>

                </Container>
            </PageSection>

        </PageLayout>
    );
};

export default AboutWeb3;
